export enum Color {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary',
  SUCCESS = 'success',
  INFO = 'info',
  WARNING = 'warning',
  DANGER = 'danger',
  GRAY = 'gray',
}

export enum Operator {
  LOWER_THAN = '<',
  GREATER_THAN = '>',
  LOWER_THAN_OR_EQUAL = '<=',
  GREATER_THAN_OR_EQUAL = '>=',
  IS_NOT = '!=',
  IS = '=',
}

export enum Field {
  ACTION_FIELD = 'ActionField',
  BOOLEAN_FIELD = 'BooleanField',
  CHIP_FIELD = 'ChipField',
  DATE_FIELD = 'DateField',
  EMAIL_FIELD = 'EmailField',
  FUNCTION_FIELD = 'FunctionField',
  NUMBER_FIELD = 'NumberField',
  TEXT_FIELD = 'TextField',
  URL_FIELD = 'UrlField',
  ENUM_FIELD = 'EnumField',
  PRICE_FIELD = 'PriceField',
  RELATION_FIELD = 'RelationField',
}

export enum ApiError {
  NOT_FOUND = 'NOT_FOUND',
  INVALID_CREDENTIALS = 'INVALID_CREDENTIALS',
}

export enum Api {
  MAIN = 'main',
}

export enum ImageFormat {
  JPG = 'image/jpg',
  JPEG = 'image/jpeg',
  GIF = 'image/gif',
  PNG = 'image/png',
}

export enum YupFileExtensionStatus {
  OK = 'ok',
  ERROR = 'error',
}
