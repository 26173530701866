import BaseService from './BaseService';
import { Service } from './Service';

export interface ChildResourceInterface {
  parentEndpoint: string;
  endpoint: string;
  delete: (data: DeleteConfig) => Promise<any>;
  update: (data: UpdateConfig<any>) => Promise<any>;
  create: (data: CreateConfig<any>) => Promise<any>;
}

export interface UpdateConfig<T> {
  parentId: string;
  id: string;
  data: Partial<T>;
}
export interface CreateConfig<T> {
  parentId: string;
  data: T;
}
export interface DeleteConfig {
  parentId: string;
  id: string;
}

export class ChildResourceService<T, X> extends Service implements ChildResourceInterface {
  parentEndpoint: string;

  constructor(baseService: BaseService, parentEndpoint: string, endpoint: string) {
    super(baseService, endpoint);
    this.parentEndpoint = parentEndpoint;
  }

  delete = async ({ parentId, id }: DeleteConfig) => {
    const response = await this.client.delete<X>(
      `${this.parentEndpoint}/${parentId}/${this.endpoint}/${id}`,
    );

    return response.data;
  };

  update = async ({ parentId, id, data }: UpdateConfig<T>) => {
    const response = await this.client.put<X>(
      `${this.parentEndpoint}/${parentId}/${this.endpoint}/${id}`,
      {
        ...data,
        id,
      },
    );

    return response.data;
  };

  create = async ({ parentId, data }: CreateConfig<T>) => {
    const response = await this.client.post<T>(
      `${this.parentEndpoint}/${parentId}/${this.endpoint}`,
      data,
    );

    return response.data;
  };
}
