import { useTranslation } from 'react-i18next';

import { Table } from '@socialbrothers/components/Containers';

import { AdminService } from '@Services/Admin/AdminService';
import { IAdmin } from '@Services/Admin/AdminService.props';

import { AdminTableProps } from './AdminTable.props';

export const AdminTable = ({ className }: AdminTableProps) => {
  const { t } = useTranslation();

  return (
    <Table.Resource<IAdmin> searchable service={AdminService} className={className}>
      <Table.Field.Email sortable source="email" label={t('ADMIN.LABELS.EMAIL')} />

      <Table.Field.ResourceAction
        deleteConfig={(record: IAdmin) => ({
          name: record.email,
        })}
        editConfig={(record: IAdmin) => ({
          link: `/administrators/${record.id}`,
        })}
      />
    </Table.Resource>
  );
};
