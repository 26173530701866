import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Page } from '@socialbrothers/components/Layout';
import { Card } from '@socialbrothers/components/UI';
import { useResource } from '@socialbrothers/hooks';

import { AdminForm } from '@Components/forms';
import { IUser, UserService } from '@Services/User';

export const AdminDetailPage = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const { data } = useResource<IUser>(UserService, id);

  const label = data?.email ?? t('ADMIN.SINGLE');

  return (
    <Page title={label}>
      <Card title={t('ADMIN.SINGLE')}>
        <AdminForm />
      </Card>
    </Page>
  );
};
