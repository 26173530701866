import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { ButtonWithPopup } from '@socialbrothers/components/Containers';
import { Color } from '@socialbrothers/constants';

import { DeleteProps } from './Delete.props';

const Delete = ({ children, hasIcon, hasLabel, redirect, service, id, name }: DeleteProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const queryClient = useQueryClient();

  const mutateDelete = useMutation((deleteId: string) => service.delete(deleteId), {
    onMutate: () => {
      if (redirect) {
        history.push(redirect);
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries(service.endpoint);
      toast.success(t('GLOBAL.DELETED_SUCCESSFUL', { name: name }));
    },
    onError: () => {
      toast.error(t('GLOBAL.DELETED_UNSUCCESSFUL', { name: name }));
    },
  });

  return (
    <ButtonWithPopup
      button={{
        icon: hasIcon ? 'trash-alt' : '',
        color: Color.DANGER,
        label: hasLabel ? t('GLOBAL.DELETE') : children,
      }}
      submit={{
        label: t('RESOURCE.DELETE.MODAL.PROCEED'),
        color: Color.DANGER,
        onClick: () => {
          return mutateDelete.mutateAsync(id);
        },
      }}
      popup={{
        title: t('RESOURCE.DELETE.MODAL.TITLE', {
          name: name,
        }),
      }}>
      <p>
        {t('RESOURCE.DELETE.MODAL.DESCRIPTION', {
          name: name,
        })}
      </p>
    </ButtonWithPopup>
  );
};

export default Delete;
