import { ToastContainer } from 'react-toastify';

import './App.scss';

import { ThemeProvider, useSentry } from '@socialbrothers/hooks';
import { yupSetup } from '@socialbrothers/utils';

import config from '@Config';

import { RootNavigator } from './routes';

yupSetup();

const App = () => {
  useSentry();

  return (
    <ThemeProvider context={config}>
      <ToastContainer />
      <RootNavigator />
    </ThemeProvider>
  );
};

export default App;
