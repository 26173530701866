import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import { ButtonWithPopup, Form } from '@socialbrothers/components/Containers';
import { Color } from '@socialbrothers/constants';
import { modelToOptions, Yup } from '@socialbrothers/utils';

import { useHubs } from '@Hooks/index';
import { HubService } from '@Services/Hub';
import { UserHubService } from '@Services/UserHub';

import { UserHubFormProps, UserHubFormValues } from './UserHubForm.props';

export const UserHubForm = ({ userId }: UserHubFormProps) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { data: hubs } = useHubs(userId);

  const mutateCreate = useMutation(
    (values: UserHubFormValues) =>
      UserHubService.create({
        userId: userId,
        ...values,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([UserHubService.endpoint]);
        queryClient.invalidateQueries([HubService.endpoint]);
        toast.success(t('GLOBAL.CREATED_SUCCESSFUL', { name: t('HUB.SINGLE') }));
      },

      onError: () => {
        toast.error(
          t('GLOBAL.CREATED_UNSUCCESSFUL', {
            name: t('HUB.SINGLE'),
          }),
        );
      },
    },
  );

  const handleCreate = useCallback(
    (values: UserHubFormValues) => {
      return mutateCreate.mutateAsync(values);
    },
    [mutateCreate],
  );

  const validationSchema = Yup.object().shape({
    hubId: Yup.string().required(),
  });

  return (
    <ButtonWithPopup
      withForm
      submit={{
        label: t('GLOBAL.CREATE'),
        onClick: handleCreate,
      }}
      validationSchema={validationSchema}
      popup={{
        title: t('GLOBAL.CREATE_MODEL', {
          name: t('HUB.SINGLE'),
        }),
      }}
      button={{
        label: t('GLOBAL.CREATE_MODEL', {
          name: t('HUB.SINGLE'),
        }),
        icon: 'plus',
        color: Color.SECONDARY,
      }}>
      {hubs && (
        <>
          <Form.Layout.Field translationKey="HUB.LABELS.HUB_ID">
            <Form.Input.Select name="hubId" options={modelToOptions(hubs.results, 'name')} />
          </Form.Layout.Field>
        </>
      )}
    </ButtonWithPopup>
  );
};
