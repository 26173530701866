import { ListResponse } from '@socialbrothers/constants';
import { Service } from '@socialbrothers/services/Service';

import { ApiService } from '@Services/index';

import { IHub } from './HubService.props';

export class HubServiceImplementation extends Service {
  getList = async (userId?: string) => {
    const response = await this.client.get<ListResponse<IHub>>('/admin/hubs', {
      excludeForUserId: userId,
    });

    return response.data;
  };
}

export const HubService = new HubServiceImplementation(ApiService, '/admin/hubs');
