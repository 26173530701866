import { useTranslation } from 'react-i18next';

import { Table } from '@socialbrothers/components/Containers';

import { IUser, UserService } from '@Services/User';

import { UserTableProps } from './UserTable.props';

export const UserTable = ({ className, defaultFilters }: UserTableProps) => {
  const { t } = useTranslation();

  return (
    <Table.Resource<IUser>
      searchable
      defaultFilters={defaultFilters}
      service={UserService}
      className={className}>
      <Table.Field.Email sortable source="email" label={t('USER.LABELS.EMAIL')} />

      <Table.Field.Boolean
        width={1}
        sortable
        source="accountCreated"
        label={t('USER.LABELS.ACCOUNT_CREATED')}
      />

      <Table.Field.Date width={1} sortable source="createdAt" label={t('GLOBAL.CREATED_AT')} />

      <Table.Field.ResourceAction
        deleteConfig={(record: IUser) => ({
          name: record.email,
        })}
        editConfig={(record: IUser) => ({
          link: `/apps/${record.app?.id}/${record.id}`,
        })}
      />
    </Table.Resource>
  );
};
