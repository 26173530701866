import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Page } from '@socialbrothers/components/Layout';
import { Card } from '@socialbrothers/components/UI';
import { Operator } from '@socialbrothers/constants';
import { useResource } from '@socialbrothers/hooks';
import { isCreate } from '@socialbrothers/utils';

import { AppForm, UserInviteForm } from '@Components/forms';
import { UserTable } from '@Components/tables';
import { AppService, IApp } from '@Services/App';

export const AppDetailPage = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const { data: app } = useResource<IApp>(AppService, id);

  const label = app?.title ?? t('APP.SINGLE');

  return (
    <Page title={label}>
      <Card title={t('APP.SINGLE')}>
        <AppForm />
      </Card>

      {!isCreate(id) && !!app && (
        <Card title={t('USER.PLURAL')}>
          <div className="text-right">
            <UserInviteForm appId={app.id} />
          </div>

          <UserTable
            defaultFilters={{
              'app.id': [
                {
                  operator: Operator.IS,
                  value: app?.id,
                },
              ],
            }}
          />
        </Card>
      )}
    </Page>
  );
};
