import format from 'date-fns/format';
import DatePicker from 'react-datepicker';
import { Controller, useFormContext } from 'react-hook-form';

import 'react-datepicker/dist/react-datepicker.css';

import { Icon } from '@socialbrothers/components/UI';

import styles from './DateInput.module.scss';
import { DateInputProps } from './DateInput.props';

const DateInput = ({ name, onChange, showTime, initialDate, ...props }: DateInputProps) => {
  const { control } = useFormContext();

  return (
    <div className={styles.DateInput} {...props}>
      <Controller
        name={name}
        control={control}
        defaultValue={initialDate ? format(initialDate, 'yyyy-MM-dd') : undefined}
        render={({ onChange: onChangeController, value }) => {
          const handleChange = (newValue: Date) => {
            onChangeController(showTime ? newValue.toISOString() : format(newValue, 'yyyy-MM-dd'));
          };

          return (
            <>
              <DatePicker
                selected={value ? new Date(value) : undefined}
                showTimeSelect={showTime}
                onChange={handleChange}
                showYearDropdown
                locale="nl"
                showMonthDropdown
                dateFormat={`dd-MM-yyyy${showTime ? ' HH:mm' : ''}`}
                dropdownMode="select"
              />

              <Icon className={styles.DateInput__Icon} icon="calendar" type="regular" />
            </>
          );
        }}
      />
    </div>
  );
};

export default DateInput;
