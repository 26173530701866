import i18n from 'i18next';

import { Routes } from '@socialbrothers/constants';
import { MyAccountPage, NotFoundPage } from '@socialbrothers/pages';

const routes: Routes = [
  {
    key: 'MyAccountPage',
    exact: true,
    path: '/account',
    component: MyAccountPage,
    icon: 'user',
    label: i18n.t('ACCOUNT.TITLE'),
  },
  {
    key: 'NotFoundPage',
    label: i18n.t('NOT_FOUND.LABEL'),
    icon: 'user',
    path: '/not-found',
    component: NotFoundPage,
  },
];

export default routes;
