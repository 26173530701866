import cn from 'classnames';
import { useFormContext } from 'react-hook-form';
import { Link } from 'react-router-dom';

import { Icon, Spinner } from '@socialbrothers/components/UI';
import { Color } from '@socialbrothers/constants';

import styles from './Button.module.scss';
import { ButtonProps } from './Button.props';

const Button = ({
  children,
  icon,
  to,
  href,
  label,
  isLoading,
  onClick,
  block,
  size,
  color,
  link,
  className,
  disabled,
  type,
  ...props
}: ButtonProps) => {
  const classes = cn(['button', styles.Button], {
    [`${className}`]: !!className,
    [styles[`Button--${size}`]]: size,
    [styles['Button--Disabled']]: disabled,
    [styles['Button--Link']]: link,
    [styles['Button--Block']]: block,
    [styles[`Color${color}`]]: color,
  });

  const formContext = useFormContext();

  const isLoadingOrSubmitting =
    isLoading || (type === 'submit' && formContext?.formState?.isSubmitting);

  const getContent = () => {
    return (
      <div
        className={cn({
          [styles['Button--Loading']]: isLoadingOrSubmitting,
        })}>
        <div className={styles.Button__Content}>
          {icon && <Icon className={styles.Button__Icon} icon={icon} />}
          {children && <div className={styles.Button__Children}>{children}</div>}
          {label && <div className={styles.Button__Children}>{label}</div>}
        </div>

        {isLoadingOrSubmitting && (
          <Spinner size={20} color="white" className={styles.Button__Loader} />
        )}
      </div>
    );
  };

  if (href) {
    return (
      <a className={classes} href={href} target="_blank" rel="noopener noreferrer" {...props}>
        {getContent()}
      </a>
    );
  }

  if (to) {
    return (
      <Link to={to} className={classes} {...props}>
        {getContent()}
      </Link>
    );
  }

  return (
    <button onClick={onClick} disabled={disabled} className={classes} type={type} {...props}>
      {getContent()}
    </button>
  );
};

Button.defaultProps = {
  color: Color.PRIMARY,
};

export default Button;
