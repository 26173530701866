import { useTranslation } from 'react-i18next';

import { Table } from '@socialbrothers/components/Containers';
import { Operator } from '@socialbrothers/constants';

import { IUserHub, UserHubService } from '@Services/UserHub';

import { UserHubTableProps } from './UserHubTable.props';

export const UserHubTable = ({ className, userId }: UserHubTableProps) => {
  const { t } = useTranslation();

  return (
    <Table.Resource<IUserHub>
      defaultFilters={{
        userId: [
          {
            operator: Operator.IS,
            value: userId,
          },
        ],
      }}
      service={UserHubService}
      className={className}>
      <Table.Field.Text sortable source="hub.name" label={t('HUB.LABELS.NAME')} />

      <Table.Field.Date width={1} sortable source="createdAt" label={t('GLOBAL.CREATED_AT')} />

      <Table.Field.ResourceAction
        deleteConfig={(record: IUserHub) => ({
          name: record.hub.name,
        })}
      />
    </Table.Resource>
  );
};
