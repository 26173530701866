import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Page } from '@socialbrothers/components/Layout';
import { Card } from '@socialbrothers/components/UI';
import { useBreadcrumbs, useResource } from '@socialbrothers/hooks';

import { UserHubForm } from '@Components/forms';
import { UserHubTable } from '@Components/tables/UserHubTable/UserHubTable';
import { routes } from '@Routes/routes';
import { AppService, IApp } from '@Services/App';
import { IUser, UserService } from '@Services/User';

export const AppUserDetailPage = () => {
  const { t } = useTranslation();
  const { appId, id } = useParams<{ appId: string; id: string }>();
  const { data: app } = useResource<IApp>(AppService, appId);
  const { data: user } = useResource<IUser>(UserService, id);

  const label = user?.email ?? t('USER.SINGLE');

  const items = useBreadcrumbs(routes, label);

  if (items[2] && items[2].key === 'AppDetailPage' && app) {
    items[2].label = app.title;
  }

  return (
    <Page title={label} breadcrumbs={items}>
      {!!app && !!user && (
        <Card title={t('HUB.PLURAL')}>
          <div className="text-right">
            <UserHubForm userId={user.id} />
          </div>

          <UserHubTable userId={user.id} />
        </Card>
      )}
    </Page>
  );
};
