import { Header, Routes, Sidebar } from '@socialbrothers/constants/interfaces';
import { getRouteByKey } from '@socialbrothers/utils';

import {
  AdminDetailPage,
  AdminOverviewPage,
  AppDetailPage,
  AppOverviewPage,
  AppUserDetailPage,
} from '@Pages/index';
import i18n from '@Root/i18n';

export const routes: Routes = [
  {
    key: 'AdminDetailPage',
    label: i18n.t('ADMIN.SINGLE'),

    path: '/administrators/:id',
    component: AdminDetailPage,
    exact: true,
  },
  {
    key: 'AdminOverviewPage',
    label: i18n.t('ADMIN.PLURAL'),

    path: '/administrators',
    component: AdminOverviewPage,
    exact: true,
  },
  {
    key: 'AppUserDetailPage',
    label: i18n.t('USER.SINGLE'),

    path: '/apps/:appId/:id',
    component: AppUserDetailPage,
    exact: true,
  },
  {
    key: 'AppDetailPage',
    label: i18n.t('APP.SINGLE'),

    path: '/apps/:id',
    component: AppDetailPage,
    exact: true,
  },
  {
    key: 'AppOverviewPage',
    label: i18n.t('APP.PLURAL'),

    path: '/apps',
    component: AppOverviewPage,
    exact: true,
  },
];

export const sidebar: Sidebar = [
  {
    title: i18n.t('GLOBAL.GENERAL'),
    routes: [getRouteByKey(routes, 'AppOverviewPage')],
  },
];

export const header: Header = [
  {
    label: i18n.t('ACCOUNT.TITLE'),
    link: '/account',
  },
  {
    label: i18n.t('ADMIN.PLURAL'),
    link: '/administrators',
  },
];
