import { useFormContext } from 'react-hook-form';

import { NumberInputProps } from './NumberInput.props';

const NumberInput = ({ ...props }: NumberInputProps) => {
  const { register } = useFormContext();

  return <input ref={register()} type="number" step="any" {...props} />;
};

export default NumberInput;
