import ENUM from './enum.json';
import GLOBAL from './global.json';

const data = {
  translation: {
    GLOBAL,
    ENUM,
  },
};

export default data;
