import { Api, LoginConfig, LoginResponse, TokenResponse } from '@socialbrothers/constants';
import { Storage } from '@socialbrothers/helpers';
import { BaseService } from '@socialbrothers/services';

import { ApiService } from '@Services/index';

class AuthServiceImplementation {
  service: BaseService;

  constructor(service: BaseService) {
    this.service = service;
  }

  login = async (credentials: LoginConfig): Promise<LoginResponse> => {
    const response = await this.service.post<LoginResponse>('/auth/login', credentials);

    Storage.setAccessToken(Api.MAIN, response.data as TokenResponse);
    this.service.setAccessToken(response.data as TokenResponse);

    return response.data;
  };

  logout = async () => {
    Storage.removeAccessToken(Api.MAIN);
    this.service.removeAccessToken();

    return true;
  };

  resetPassword = async (
    email: string,
    token: string,
    newPassword: string,
    newPasswordConfirmation: string,
  ) => {
    const result = await this.service.post('/auth/reset', {
      email,
      token,
      password: newPassword,
      passwordConfirmation: newPasswordConfirmation,
    });

    return result.data;
  };

  forgotPassword = async (email: string) => {
    const result = await this.service.post('/auth/forgot', {
      email,
    });

    return result.data;
  };
}

export const AuthService = new AuthServiceImplementation(ApiService);
