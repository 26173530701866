import cn from 'classnames';
import { observer } from 'mobx-react';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, matchPath, NavLink, useLocation } from 'react-router-dom';

import { Icon } from '@socialbrothers/components/UI';
import { RoutesLink, SidebarGroup, SidebarItem } from '@socialbrothers/constants';
import { useEscape } from '@socialbrothers/hooks/index';

import { useStores } from '@Hooks/index';
import { sidebar } from '@Routes/index';

import styles from './Sidebar.module.scss';
import { ItemProps } from './Sidebar.props';

const Item = ({ item }: ItemProps) => {
  var location = useLocation();

  const isActive =
    (!!matchPath(location.pathname, {
      path: item.path,
      exact: false,
      strict: false,
    }) &&
      item.path !== '/') ||
    item.path === location.pathname;

  if (item.url) {
    return (
      <a href={item.url} className={styles.Item} target="_blank" rel="noopener noreferrer">
        <Icon className={styles.Icon} icon={item.icon ?? 'exclamation-circle'} />

        {item.label}
      </a>
    );
  }
  return (
    <NavLink
      className={cn([styles.Item], {
        [styles.ItemActive]: isActive,
      })}
      to={item.path || ''}>
      <Icon className={styles.Icon} icon={item.icon ?? 'exclamation-circle'} />

      {item.label}
    </NavLink>
  );
};

const Dropdown = ({ item }: ItemProps) => {
  var location = useLocation();
  const isActive = matchPath(location.pathname, {
    path: item.path,
    exact: false,
    strict: false,
  });

  const renderItem = () => (
    <NavLink
      to={item.path}
      className={cn([styles.Item, styles.ItemDropdown], {
        [styles.ItemActive]: isActive,
      })}>
      <Icon className={styles.Icon} icon={item.icon ?? 'exclamation-circle'} />
      {item.label}
    </NavLink>
  );

  const renderDropdown = () =>
    item.children && (
      <ul className={cn([styles.Dropdown], { [styles.DropdownActive]: isActive })}>
        {item.children.map((link: RoutesLink) => {
          const active = !!matchPath(location.pathname, {
            path: link.path,
            exact: link.exact,
            strict: false,
          });

          return (
            <li key={link.path}>
              <NavLink
                className={cn(styles.Subitem, {
                  [styles.SubitemActive]: active,
                })}
                to={link.path}>
                {link.label}
              </NavLink>
            </li>
          );
        })}
      </ul>
    );

  return (
    <>
      {renderItem()}
      {renderDropdown()}
    </>
  );
};

const Sidebar = () => {
  const { ThemeStore } = useStores();
  const { t } = useTranslation();
  const location = useLocation();
  const isFirstEffect = useRef(false);

  useEffect(() => {
    if (!isFirstEffect.current) {
      if (ThemeStore.isMobile) {
        ThemeStore.Sidebar.setIsOpen(false);
      }

      isFirstEffect.current = true;
      return;
    }
  }, [location.pathname, ThemeStore.Sidebar, ThemeStore.isMobile]);

  useEscape(() => {
    if (ThemeStore.isMobile) {
      ThemeStore.Sidebar.setIsOpen(false);
    }
  });

  if (ThemeStore.Sidebar.isOpen) {
    return (
      <>
        <div className={styles.Overlay} onClick={ThemeStore.Sidebar.toggleIsOpen} />

        <div className={styles.Sidebar}>
          <Link to="/" className={styles.Brand}>
            {t('GLOBAL.SITE_NAME')}
          </Link>

          <ul>
            {sidebar.map((group: SidebarGroup, index: number) => (
              <li key={index}>
                <div className={styles.Title}>{group.title}</div>

                <ul>
                  {group.routes.map((item: SidebarItem, idx: number) => (
                    <li key={idx}>
                      {item.children ? <Dropdown item={item} /> : <Item item={item} />}
                    </li>
                  ))}
                </ul>
              </li>
            ))}
          </ul>
        </div>
      </>
    );
  }

  return null;
};

export default observer(Sidebar);
