import { useTranslation } from 'react-i18next';

import { Table } from '@socialbrothers/components/Containers';

import { AppService, IApp } from '@Services/App';

import { AppTableProps } from './AppTable.props';

export const AppTable = ({ className }: AppTableProps) => {
  const { t } = useTranslation();

  return (
    <Table.Resource<IApp> searchable service={AppService} className={className}>
      <Table.Field.Text sortable source="title" label={t('APP.LABELS.TITLE')} />

      <Table.Field.Date width={1} sortable source="createdAt" label={t('GLOBAL.CREATED_AT')} />

      <Table.Field.ResourceAction
        deleteConfig={(record: IApp) => ({
          name: record.title,
        })}
        editConfig={(record: IApp) => ({
          link: `/apps/${record.id}`,
        })}
      />
    </Table.Resource>
  );
};
