import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { ButtonWithPopup, Form } from '@socialbrothers/components/Containers';
import { Color } from '@socialbrothers/constants';
import { Yup } from '@socialbrothers/utils';

import { useUserInvite } from '@Hooks/index';
import { UserService } from '@Services/User';

import { UserInviteFormProps, UserInviteFormValues } from './UserInviteForm.props';

export const UserInviteForm = ({ appId }: UserInviteFormProps) => {
  const { t } = useTranslation();
  const userInviteMutation = useUserInvite();

  const handleCreate = useCallback(
    async (values: UserInviteFormValues) => {
      try {
        await userInviteMutation.mutateAsync({
          appId: appId,
          ...values,
        });

        toast.success(t('GLOBAL.CREATED_SUCCESSFUL', { name: t('USER.SINGLE') }));
      } catch (e) {
        toast.error(
          t('GLOBAL.CREATED_UNSUCCESSFUL', {
            name: t('USER.SINGLE'),
          }),
        );
      }
    },
    [appId, t, userInviteMutation],
  );

  const validationSchema = Yup.object().shape({
    email: Yup.string().email().async(UserService.validate).required(),
  });

  return (
    <ButtonWithPopup
      withForm
      submit={{
        label: t('GLOBAL.CREATE'),
        onClick: handleCreate,
      }}
      validationSchema={validationSchema}
      popup={{
        title: t('GLOBAL.CREATE_MODEL', {
          name: t('USER.SINGLE'),
        }),
      }}
      button={{
        label: t('GLOBAL.CREATE_MODEL', {
          name: t('USER.SINGLE'),
        }),
        icon: 'plus',
        color: Color.SECONDARY,
      }}>
      <Form.Layout.Field translationKey="USER.LABELS.EMAIL">
        <Form.Input.Text name="email" />
      </Form.Layout.Field>
    </ButtonWithPopup>
  );
};
