import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Form } from '@socialbrothers/components/Containers';
import { useResource } from '@socialbrothers/hooks';
import { isCreate, Yup } from '@socialbrothers/utils';

import { AdminService, IAdmin } from '@Services/Admin';

export const AdminForm = () => {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const isCreating = isCreate(id);
  const { data } = useResource<IAdmin>(AdminService, !isCreating ? id : undefined);

  const validationSchema = Yup.object().shape({
    email: Yup.string().mail().async(AdminService.validate, data?.email).required(),
    password: isCreating ? Yup.string().password().required() : Yup.string(),
    passwordConfirmation: isCreating ? Yup.string().password('password').required() : Yup.string(),
  });

  return (
    <Form.Resource
      service={AdminService}
      validationSchema={validationSchema}
      label={t('ADMIN.SINGLE')}
      id={id}>
      <Form.Layout.Field translationKey="ADMIN.LABELS.EMAIL">
        <Form.Input.Text name="email" />
      </Form.Layout.Field>

      {isCreating && (
        <>
          <Form.Layout.Field translationKey="ADMIN.LABELS.PASSWORD">
            <Form.Input.Text name="password" type="password" />
          </Form.Layout.Field>

          <Form.Layout.Field translationKey="ADMIN.LABELS.PASSWORD_CONFIRMATION">
            <Form.Input.Text name="passwordConfirmation" type="password" />
          </Form.Layout.Field>
        </>
      )}
    </Form.Resource>
  );
};
