import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Form } from '@socialbrothers/components/Containers';
import { Button } from '@socialbrothers/components/UI';
import { useTheme } from '@socialbrothers/hooks';
import { Yup } from '@socialbrothers/utils';

import styles from './ForgotPasswordForm.module.scss';
import { ForgotPasswordFormProps } from './ForgotPasswordForm.props';

const ForgotPasswordForm = ({ ...props }: ForgotPasswordFormProps) => {
  const [hasSubmitted, setSubmitted] = useState(false);
  const { t } = useTranslation();
  const [error, setError] = useState('');
  const { authService } = useTheme();

  const validationSchema = Yup.object().shape({
    email: Yup.string().email().required(),
  });

  const handleSubmit = async (values: any) => {
    try {
      await authService.forgotPassword(values.email);
      setSubmitted(true);
    } catch (e) {
      setError(t('VALIDATION.COMMON.UNKNOWN_ERROR'));
    }
  };

  return hasSubmitted ? (
    <div className={styles.ForgotPasswordForm}>
      {t('AUTHENTICATION.FORGOT_PASSWORD.SUBMISSION_SUCCESSFUL')}

      <hr className={styles.Seperator} />

      <div className={styles.FooterLinks}>
        <Link to="" className="link">
          {t('AUTHENTICATION.FORGOT_PASSWORD.BACK')}
        </Link>
      </div>
    </div>
  ) : (
    <div className={styles.ForgotPasswordForm}>
      <Form.Base
        onSubmit={handleSubmit}
        error={error}
        {...props}
        validationSchema={validationSchema}>
        <Form.Layout.Field translationKey="FORMS.COMMON_LABELS.EMAIL">
          <Form.Input.Text name="email" />
        </Form.Layout.Field>

        <div className={styles.Footer}>
          <Button block type="submit">
            {t('AUTHENTICATION.FORGOT_PASSWORD.SUBMIT_LABEL')}
          </Button>

          <hr className={styles.Seperator} />

          <div className={styles.FooterLinks}>
            <Link to="" className="link">
              {t('AUTHENTICATION.FORGOT_PASSWORD.BACK')}
            </Link>
          </div>
        </div>
      </Form.Base>
    </div>
  );
};

export default ForgotPasswordForm;
