import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import { Form } from '@socialbrothers/components/Containers';
import { isCreate, serialize } from '@socialbrothers/utils';

import { ChildResourceFormProps } from './ChildResourceForm.props';

function ResourceForm<T>(
  {
    id,
    parentId,
    service,
    submitLabel,
    children,
    validationSchema,
    label,
    preSubmitFormatter,
    onError,
    onSuccess,
    initialValues,
    disabled,
  }: ChildResourceFormProps<T>,
  ref: any,
) {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const mutateCreate = useMutation((values) => service.create(values as any), {
    onSuccess: (response: any) => {
      queryClient.invalidateQueries([service.parentEndpoint, parentId, service.endpoint]);

      toast.success(t('GLOBAL.CREATED_SUCCESSFUL', { name: label }));
      queryClient.invalidateQueries();

      onSuccess && onSuccess(response.response.data);
    },
    onError: (response: any) => {
      toast.error(t('GLOBAL.CREATED_UNSUCCESSFUL', { name: label }));

      onError && onError(response.response.data);
    },
  });

  const mutateUpdate = useMutation(
    (values) => service.update({ id, parentId, data: values as any }),
    {
      onSuccess: (response: any) => {
        toast.success(t('GLOBAL.UPDATED_SUCCESSFUL', { name: label }));
        queryClient.invalidateQueries(service.endpoint);

        onSuccess && onSuccess(response.response.data);
      },
      onError: (response: any) => {
        toast.error(t('GLOBAL.UPDATED_UNSUCCESSFUL', { name: label }));

        onError && onError(response.response.data);
      },
    },
  );

  const defaultSubmitLabel = isCreate(id) ? t('GLOBAL.CREATE') : t('GLOBAL.UPDATE');

  const handleSubmit = (values: any) => {
    const params = preSubmitFormatter ? preSubmitFormatter(values, !!id) : values;

    if (isCreate(id)) {
      return mutateCreate.mutateAsync(serialize(params));
    } else {
      return mutateUpdate.mutateAsync(serialize(params));
    }
  };

  return (
    <Form.Base
      ref={ref}
      submitLabel={submitLabel || defaultSubmitLabel}
      initialValues={initialValues}
      validationSchema={validationSchema}
      disabled={disabled}
      onSubmit={handleSubmit}>
      {children}
    </Form.Base>
  );
}

export default forwardRef(ResourceForm);
