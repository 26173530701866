import { FilterFormEntry } from '../components/Containers/Table/Filter/Filter.props';
import { Filter } from '../constants';

export const serialize = (values: any) => {
  for (const [key, value] of Object.entries(values)) {
    const isFile = document.getElementById(key)?.outerHTML.includes('type="file"');

    if (isFile && value instanceof File === false) {
      delete values[key];
    }
  }

  return values;
};

export const formatFilterToFormValues = (filter: Filter<any>) => {
  return Object.entries(filter).reduce<FilterFormEntry[]>((total, [key, array]) => {
    if (array) {
      array.forEach(({ operator, value }) => {
        total.push({
          field: key,
          operator: operator,
          value: value,
        });
      });
    }

    return total;
  }, []);
};
