import { Account, AccountServiceInterface } from '@socialbrothers/constants';
import { BaseService } from '@socialbrothers/services';

import { ApiService } from '@Services/index';

class AccountServiceImplementation implements AccountServiceInterface {
  client: BaseService;

  endpoint: string;

  constructor(client: BaseService, endpoint: string) {
    this.endpoint = endpoint;
    this.client = client;
  }

  me = async () => {
    const result = await this.client.get<Account>(`${this.endpoint}/me`);

    return result.data;
  };

  updateMe = async (data: Account) => {
    const result = await this.client.put<Account>(`${this.endpoint}/${data.id}`, data);

    return result.data;
  };
}

export const AccountService = new AccountServiceImplementation(ApiService, '/admin/admins');
