import { useFormContext } from 'react-hook-form';

import { HiddenInputProps } from './HiddenInput.props';

const HiddenInput = ({ ...props }: HiddenInputProps) => {
  const { register } = useFormContext();

  return <input ref={register()} type="hidden" {...props} />;
};

export default HiddenInput;
