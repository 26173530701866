import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Form } from '@socialbrothers/components/Containers';
import { isCreate, Yup } from '@socialbrothers/utils';

import { AppService } from '@Services/App';

export const AppForm = () => {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();

  const validationSchema = Yup.object().shape({
    title: Yup.string().required(),
    androidUrl: Yup.string().required(),
    iosUrl: Yup.string().required(),
    favIcon: Yup.string().required(),
    logo: Yup.string().required(),
  });

  return (
    <Form.Resource
      service={AppService}
      validationSchema={validationSchema}
      label={t('APP.SINGLE')}
      id={id}>
      <Form.Layout.Field translationKey="APP.LABELS.TITLE">
        <Form.Input.Text name="title" />
      </Form.Layout.Field>

      {!isCreate(id) && (
        <Form.Layout.Field translationKey="APP.LABELS.SLUG">
          <Form.Input.Text name="slug" disabled />
        </Form.Layout.Field>
      )}

      <Form.Layout.Field translationKey="APP.LABELS.ANDROID_URL">
        <Form.Input.Text name="androidUrl" />
      </Form.Layout.Field>

      <Form.Layout.Field translationKey="APP.LABELS.IOS_URL">
        <Form.Input.Text name="iosUrl" />
      </Form.Layout.Field>

      <Form.Layout.Field translationKey="APP.LABELS.FAV_ICON">
        <Form.Input.Text name="favIcon" />
      </Form.Layout.Field>

      <Form.Layout.Field translationKey="APP.LABELS.LOGO">
        <Form.Input.Text name="logo" />
      </Form.Layout.Field>
    </Form.Resource>
  );
};
