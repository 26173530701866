import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';

import { DropdownButton, Icon } from '@socialbrothers/components/UI';
import { useTheme } from '@socialbrothers/hooks';

import { useStores } from '@Hooks/index';
import { header } from '@Routes/routes';

import styles from './Header.module.scss';

const Header = () => {
  const { AuthStore, ThemeStore } = useStores();
  const { t } = useTranslation();
  const { authService, accountService } = useTheme();
  const { data } = useQuery(['users', 'me'], accountService.me);
  const history = useHistory();

  const handleLogout = () => {
    authService.logout();
    AuthStore.setIsAuthenticated(false);
    history.push('/');
  };

  const dropdownItems = [
    ...header,

    {
      onPress: handleLogout,
      label: t('AUTHENTICATION.LOG_OUT'),
    },
  ];

  return (
    <div className={styles.Header}>
      <Icon
        className={styles.Header__Toggle}
        icon="bars"
        onClick={ThemeStore.Sidebar.toggleIsOpen}
      />

      <div className="ml-auto">
        <DropdownButton items={dropdownItems}>
          <div className={styles.Header__Avatar}>
            <div className={styles.Header__AvatarCircle}>
              <Icon icon="user" />
            </div>
            <span className={styles.Header__Email}>{data?.email}</span>
          </div>
        </DropdownButton>
      </div>
    </div>
  );
};

export default observer(Header);
