import { ResourceService } from '@socialbrothers/services';

import { queryClient } from '@Root/index';
import { HubService } from '@Services/Hub';
import { ApiService } from '@Services/index';

import { IUserHub } from './UserHubService.props';

export class UserHubServiceImplementation extends ResourceService<IUserHub> {
  delete = async (id: string) => {
    const response = await this.client.delete<IUserHub>(`${this.endpoint}/${id}`);

    queryClient.invalidateQueries([HubService.endpoint]);

    return response.data;
  };
}

export const UserHubService = new UserHubServiceImplementation(ApiService, '/admin/userhubs');
