import { ResourceService } from '@socialbrothers/services';

import { ApiService } from '@Services/index';

import { IUser, UserInviteRequest } from './UserService.props';

export class UserServiceImplementation extends ResourceService<IUser> {
  invite = async (params: UserInviteRequest) => {
    await this.client.post<void>('/admin/users/invite', params);
  };
}

export const UserService = new UserServiceImplementation(ApiService, '/admin/users');
