import { useTranslation } from 'react-i18next';

import { ForgotPasswordForm } from '@socialbrothers/components/Forms';
import { AuthWrapper } from '@socialbrothers/components/Layout';
import { useTheme } from '@socialbrothers/hooks';

const ForgotPasswordPage = () => {
  const { forgotPasswordImage } = useTheme();
  const { t } = useTranslation();

  const initialValues = {
    email: '',
    password: '',
  };

  return (
    <AuthWrapper
      imageUri={forgotPasswordImage}
      title={t('AUTHENTICATION.FORGOT_PASSWORD.PAGE_TITLE')}>
      <ForgotPasswordForm initialValues={initialValues} />
    </AuthWrapper>
  );
};

export default ForgotPasswordPage;
