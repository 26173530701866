/* eslint-disable react-hooks/exhaustive-deps */
import cn from 'classnames';
import { get } from 'lodash';
import { observer } from 'mobx-react';
import { cloneElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '@socialbrothers/components/UI';
import { Field, FieldProps } from '@socialbrothers/constants';
import { SortDirection } from '@socialbrothers/stores';
import { formatPrice } from '@socialbrothers/utils';

import styles from './BaseTable.module.scss';
import { BaseTableBodyProps, BaseTableProps, TableActionsProps } from './BaseTable.props';

const TableActions = ({ actions, selected, className }: TableActionsProps) => {
  const { t } = useTranslation();

  return (
    <div className={cn(styles.TableActions, className)}>
      <div>{t('TABLE.ACTIONS.ROWS_SELECTED', { count: selected.length })}</div>
      <div className={styles.TableActions__ActionsWrapper}>
        {actions.map((item: any) => {
          return (
            <div
              key={item.id}
              className={cn(styles.TableActions__Action, styles.Action)}
              onClick={() => item.onClick(selected)}>
              <Icon className={styles.Action__Icon} icon={item.icon} />
              <span className={styles.Action__Label}>{item.label}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const TableBody = observer(
  ({
    tableFooter,
    fields,
    filter,
    actions,
    data,
    onActionsBarClose,
    onActionsBarOpen,
    onRowClick,
  }: BaseTableBodyProps) => {
    const { t } = useTranslation();
    const items = fields.map((child: JSX.Element) => child.props);
    const [selected, setSelected] = useState<Array<any>>([]);
    const showActionBar = selected.length > 0 && actions && actions.length > 0;

    const showSelects = actions && actions.length > 0;

    useEffect(() => {
      if (showActionBar) {
        onActionsBarOpen && onActionsBarOpen();
      } else {
        onActionsBarClose && onActionsBarClose();
      }
    }, [showActionBar, onActionsBarOpen, onActionsBarClose]);

    useEffect(() => {
      setSelected((current) => {
        return current.filter(({ id }) => {
          return data.some((item) => {
            return item.id === id;
          });
        });
      });
    }, [data]);

    useEffect(() => {
      items.forEach((item) => {
        if (item.defaultSort) {
          filter?.setSort(item.source);

          if (typeof item.defaultSort === 'string') {
            filter?.setSortDirection(item.defaultSort);
          }
        }
      });
    }, [filter]); // if items is used as dependency, this will keep updating the table

    const isSelected = (item: any) => {
      return selected.some(({ id }) => {
        return item.id === id;
      });
    };

    const addItem = (item: any) => {
      setSelected((current) => {
        return [...current, item];
      });
    };

    const removeItem = ({ id }: any) => {
      setSelected((current) => {
        return current.filter((item) => {
          return item.id !== id;
        });
      });
    };

    const toggleItem = (item: any) => {
      if (isSelected(item)) {
        removeItem(item);
      } else {
        addItem(item);
      }
    };

    const toggleAll = () => {
      if (selected.length === data.length) {
        setSelected([]);
      } else {
        setSelected([...data]);
      }
    };

    const getTableHead = () => (
      <thead>
        <tr>
          {showSelects && (
            <th>
              <input
                onChange={toggleAll}
                checked={selected.length === data.length}
                id="toggleAll"
                type="checkbox"
              />
              <label htmlFor="toggleAll"></label>
            </th>
          )}

          {items.map((item: FieldProps, index: number) => {
            const isSecondHalf = index > items.length / 2;

            if (item.hidden) {
              return null;
            }

            const style = {
              width: 'auto',
            };

            if (item.source) {
              style.width = item.width ? `${item.width}px` : 'auto';
            } else {
              style.width = '1px';
            }

            if (item.sortable && filter) {
              return (
                <th key={item.source} style={style} onClick={() => filter.setSort(item.source)}>
                  <div className={styles.BaseTable__HeadingCell}>
                    {item.tooltip && (
                      <>
                        <Icon
                          className={styles.BaseTable__InfoIcon}
                          icon="info-circle"
                          color="#fff"
                        />
                        <div
                          className={cn('tooltip', styles.Tooltip, {
                            [styles['Tooltip--RTL']]: isSecondHalf,
                          })}>
                          <div>{item.tooltip}</div>
                        </div>
                      </>
                    )}
                    <div className={styles.Sortable}>
                      <span className={styles.Label}>{item.label}</span>

                      <div className={styles.Sortable__Icon}>
                        <i
                          className={cn(['fad'], {
                            'fa-sort': filter.sortBy !== item.source,
                            'fa-sort-up':
                              filter.sortBy === item.source &&
                              filter.sortDirection === SortDirection.ASCENDING,
                            'fa-sort-down':
                              filter.sortBy === item.source &&
                              filter.sortDirection === SortDirection.DESCENDING,
                          })}
                        />
                      </div>
                    </div>
                  </div>
                </th>
              );
            }

            return (
              <th key={item.source || index} style={style}>
                {item.tooltip && (
                  <div className={cn('tooltip', styles.Tooltip)}>
                    <div>{item.tooltip}</div>
                  </div>
                )}
                {item.label && <span className={styles.Label}>{item.label}</span>}
              </th>
            );
          })}
        </tr>
      </thead>
    );

    const getTableBody = () => (
      <tbody>
        {data.map((field: any) => {
          return (
            <tr
              key={field.id}
              className={cn(styles.BaseTable__Row, {
                [styles['BaseTable__Row--Clickable']]: !!onRowClick,
              })}>
              {showSelects && (
                <td>
                  <input
                    onChange={() => toggleItem(field)}
                    value={field.id}
                    checked={isSelected(field)}
                    id={field.id}
                    type="checkbox"
                  />
                  <label htmlFor={field.id}></label>
                </td>
              )}
              {fields.map((item: JSX.Element, index: number) => {
                if (item.props.hidden) {
                  return null;
                }

                return (
                  <td
                    title={item.props.tooltip}
                    onClick={() => {
                      onRowClick && onRowClick(field);
                    }}
                    key={item.props.source || index}>
                    {cloneElement(item, { sortable: null, filterable: null, record: field })}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    );

    const getTotals = (source: string, withtotals: string | boolean) => {
      return data.reduce((total, item) => {
        const value = parseFloat(get(item, source));

        if (typeof withtotals === 'boolean') {
          return total + value;
        }

        const multiplier = parseFloat(get(item, withtotals));
        return total + value * multiplier;
      }, 0);
    };

    const getTableFooter = () => {
      const fieldsWithTotals = fields.map((item: JSX.Element) => {
        if (item.props.withtotals) {
          return {
            ...item,
            type: item.type.displayName,
          };
        }

        return null;
      });

      if (!fieldsWithTotals.some(Boolean)) {
        return null;
      }

      return (
        <tfoot>
          <tr className="background-primary color-white">
            {fieldsWithTotals.map((item) => {
              if (!item) {
                return <td />;
              }

              return <td>{t('TABLE.FOOTER.TOTAL')}</td>;
            })}
          </tr>
          <tr>
            {fieldsWithTotals.map((item) => {
              if (!item) {
                return <td />;
              }

              const total = getTotals(item.props.source, item.props.withtotals);

              if (item.type === Field.PRICE_FIELD) {
                return <td>{formatPrice(total)}</td>;
              }

              return <td>{total}</td>;
            })}
          </tr>
        </tfoot>
      );
    };

    const getTableEmpty = () => (
      <tbody>
        <tr>
          <td colSpan={fields.length} className={styles.Empty}>
            {t('TABLE.TABLE.NO_RESULTS.DEFAULT')}
          </td>
        </tr>
      </tbody>
    );

    if (data)
      return (
        <div
          className={cn(styles.TableBody, {
            [styles['TableBody--WithTableActions']]: showActionBar,
          })}>
          {actions && actions.length > 0 && (
            <TableActions
              className={cn(styles.TableBody__Actions, {
                [styles['TableBody__Actions--Visible']]: showActionBar,
              })}
              selected={selected}
              actions={actions}
            />
          )}
          <div className={styles.TableBody__TableWrapper}>
            <table>
              {getTableHead()}
              {data.length === 0 && getTableEmpty()}
              {data.length > 0 && getTableBody()}
              {getTableFooter()}
              {tableFooter}
            </table>
          </div>
        </div>
      );

    return <></>;
  },
);

function BaseTable<T>({
  tableFooter,
  data,
  filter,
  actions,
  children,
  ...props
}: BaseTableProps<T>) {
  return (
    <div className={cn(styles.Wrapper)}>
      <TableBody
        actions={actions}
        tableFooter={tableFooter}
        data={data}
        fields={children}
        filter={filter}
        {...props}
      />
    </div>
  );
}

export default observer(BaseTable);
