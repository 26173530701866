import { useMutation, useQueryClient } from 'react-query';

import { UserInviteRequest, UserService } from '@Services/User';

export const useUserInvite = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (params: UserInviteRequest) => {
      return UserService.invite(params);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([UserService.endpoint]);
      },
    },
  );
};
