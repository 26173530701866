import { Instance, types } from 'mobx-state-tree';

import { RequestConfig } from '@socialbrothers/constants';

export enum SortDirection {
  ASCENDING = 'asc',
  DESCENDING = 'desc',
}

const FilterModel = types.model('FilterModel', {
  operator: types.maybe(types.string),
  value: types.union(types.number, types.string),
});

const TableStore = types
  .model('TableStore', {
    searchPhrase: '',
    perPage: 25,
    page: 1,
    filtersJson: types.map(types.array(FilterModel)),
    defaultFilters: types.map(types.array(FilterModel)),
    sortBy: '',
    sortDirection: types.enumeration<SortDirection>(Object.values(SortDirection)),
    totalResults: 0,
  })
  .actions((self) => ({
    setSort(source: string) {
      if (self.sortBy !== source) {
        self.sortDirection = SortDirection.ASCENDING;
        self.sortBy = source;
      } else {
        if (self.sortDirection === SortDirection.ASCENDING) {
          self.sortDirection = SortDirection.DESCENDING;
        } else {
          self.sortDirection = SortDirection.ASCENDING;
          self.sortBy = '';
        }
      }
    },

    setTotalResults(totalResults: number) {
      self.totalResults = totalResults;
    },

    setPerPage(perPage: number) {
      self.perPage = perPage;
    },

    setPage(page: number) {
      self.page = page;
    },

    setSearch(searchPhrase: string) {
      self.searchPhrase = searchPhrase;
    },

    setFilter(filter?: any) {
      if (filter) {
        var result = filter.reduce((accumulator: any, target: any) => {
          if (!target.id) {
            const { field } = target;
            delete target.field;

            return {
              ...accumulator,
              [field]: accumulator[field] ? [...accumulator[field], target] : [target],
            };
          }

          return accumulator;
        }, {});

        self.filtersJson = result;
      } else {
        self.filtersJson.clear();
      }
    },

    setSortDirection(direction: SortDirection) {
      self.sortDirection = direction;
    },
  }))
  .views((self) => ({
    get getFilter(): RequestConfig<any> {
      const filter = {
        page: self.page,
        perPage: self.perPage,
        ...(!!self.sortDirection ? { sortDirection: self.sortDirection } : {}),
        ...(!!self.sortBy ? { sortBy: self.sortBy } : {}),
        ...(!!self.searchPhrase ? { searchPhrase: self.searchPhrase } : {}),
      } as RequestConfig<any>;

      if (self.filtersJson.size > 0) {
        filter.filtersJson = { ...self.filtersJson.toJSON() } as any;
      }

      return filter;
    },
  }));

export interface TableStoreInstance extends Instance<typeof TableStore> {}
export default TableStore;
