import { Route } from 'react-router-dom';

import { Routes, RoutesLink } from '@socialbrothers/constants/interfaces';

export const getRoutesFromConfig = (routes: Routes) => {
  return routes.map((route: RoutesLink) => {
    if (!route.hidden || (route.hidden() === false && route.component)) {
      return (
        <Route key={route.path} path={route.path} exact={route.exact} component={route.component} />
      );
    }

    return null;
  });
};

export const getRouteByKey = (routes: Routes, key: string): RoutesLink => {
  const found = routes.find((route: RoutesLink) => route.key === key) as RoutesLink;

  if (found) return found;

  return {
    key: 'Unknown',
    label: 'Unknown',
    path: '/',
    component: () => null,
  } as RoutesLink;
};

export const isCreate = (id?: any) => {
  return id ? id.length !== 36 : true;
};

export const getMainRoute = (route: string, length: number) => {
  const names = route.split('/');
  return names.slice(1, length).join('/');
};
